<template>
    <div class="aa-documentos" v-if="docs">
        <span class="aa-text-h2">{{texto}}</span>
        <div class="documento m-1 p-1 text-start" v-for="documento in docs" :key="documento.id" @click="descargar(documento.attributes.url)">
            <span class="d-block icon-download"> <img src="../../../../assets/img/download.svg" /> </span>
            <span class="d-block txt-download">{{documento.attributes.caption}}</span>
        </div>
    </div>
</template>

<script>
export default {

props: {
    documentos: {
        type: Object,
        required: false
    },
    titulo: {
        type: String,
        required: true
    }
},

setup(props){
    return {
        docs: props.documentos,
        texto: props.titulo,
        descargar: (url) => window.open(url)
    }
}

}
</script>

<style lang="sass" scoped>
.aa-documentos
        .documento
            display: flex
            flex-direction: row
            border-radius: 15px
            background-color: #f1f0f5
            align-items: center
            column-gap: 15px
            cursor: pointer
            .icon-download
                background-color: #ffffff
                padding: 0.4rem
                border-radius: 50%
                height: 30px
                width: 30px
                line-height: 44px
                img
                    width: 24px
            .txt-download
                font-size: 0.9rem
                font-weight: 600
</style>